import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

// Layout
import SecondaryLanding from "../../layouts/secondary-landing";

// Components
import HeroChevron from "../hero/hero-chevron";
import BreadcrumbAuto from "../breadcrumb/breadcrumb-auto";
import SEO from "../seo/seo";
import BranchSearch from "../branch-search/branch-search";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import Icon from "../custom-widgets/icon";
import Button from "../custom-widgets/button";
import StretchedLinkCards from "../card/stretched-link-cards";
import BestBanksDefault from "../best-banks/best-banks-default";

import styles from "../card/personalization-cards.module.scss";

// Reviews Slider Styles
import "./branch.bootstrap.scss";

export const query = graphql`
  query stateHeroQuery(
    $slug: String
    $pathXXL: String
    $pathXL: String
    $pathLG: String
    $pathMD: String
    $pathSM: String
    $pathXS: String
    $pathXXS: String
    $helocCardImage: String
    $constructionCardImage: String
    $commercialCardImage: String
  ) {
    imgVariableXXL: file(relativePath: { eq: $pathXXL }) {
      ...heroChevronImageFragmentXXL
    }
    imgVariableXL: file(relativePath: { eq: $pathXL }) {
      ...heroChevronImageFragmentXL
    }
    imgVariableLG: file(relativePath: { eq: $pathLG }) {
      ...heroChevronImageFragmentLG
    }
    imgVariableMD: file(relativePath: { eq: $pathMD }) {
      ...heroChevronImageFragmentMD
    }
    imgVariableSM: file(relativePath: { eq: $pathSM }) {
      ...heroChevronImageFragmentSM
    }
    imgVariableXS: file(relativePath: { eq: $pathXS }) {
      ...heroChevronImageFragmentXS
    }
    imgVariableXXS: file(relativePath: { eq: $pathXXS }) {
      ...heroChevronImageFragmentXXS
    }
    constructionCardImage: file(relativePath: { eq: $constructionCardImage }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    helocCardImage: file(relativePath: { eq: $helocCardImage }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    commercialCardImage: file(relativePath: { eq: $commercialCardImage }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    csrIdaho: file(relativePath: { eq: "cards/state-community/thumbnail-community-idaho-122723.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    csrWashington: file(relativePath: { eq: "cards/state-community/thumbnail-community-washington-122723.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    csrNevada: file(relativePath: { eq: "cards/state-community/thumbnail-community-nevada-122723.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    csrNewMexico: file(relativePath: { eq: "cards/state-community/thumbnail-community-new-mexico-122723.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    csrOregon: file(relativePath: { eq: "cards/state-community/thumbnail-community-oregon-122723.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    csrUtah: file(relativePath: { eq: "cards/state-community/thumbnail-community-utah-122723.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    csrArizona: file(relativePath: { eq: "cards/state-community/thumbnail-community-arizona-122723.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    csrCalifornia: file(relativePath: { eq: "cards/state-community/thumbnail-california-volunteerism-020824.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    csrTexas: file(relativePath: { eq: "cards/state-community/thumbnail-community-texas-122723.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    largeCard1: file(relativePath: { eq: "cards/personalization/thumbnail-personal-credit-card-110723.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    largeCard2: file(relativePath: { eq: "cards/personalization/thumbnail-cd-special-01092023.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    greenLightCard: file(relativePath: { eq: "cards/thumbnail-greenlight-020824.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    businessLinkCard: file(
      relativePath: { eq: "cards/business-services/thumbnail-payment-processing-services-101823.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    allStrapiProducts(filter: { Name: { eq: "7-Month CD Special" } }) {
      nodes {
        Name
        ProductData {
          name
          value
        }
      }
    }
    strapiBranchState: strapiBranchStates(Slug: { eq: $slug }) {
      StateCode
      StateName
      Slug
      Description
      StateGeoLocation {
        Lat
        Lng
        Zoom
      }
      branch_locations {
        PageURL
        Address {
          City
          Country
          State
          StreetAddress
          ZipCode
        }
      }
      FeaturedBranches {
        Active
        DisplayOrder
        Title
        Subtitle
        branch_location {
          Title
          MSBookingsBranchName
          PageURL
          BranchImageUrl
        }
      }
      FeaturedReviews {
        Active
        DisplayOrder
        AuthorsName
        ReviewText
        ReviewTitle
        branch_location {
          PageURL
        }
      }
      SEO {
        MetaDescription
        MetaTitle
      }
    }
  }
`;

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  easing: "linear",
  draggable: true,
  accessibility: true,
  autoplay: true,
  autoplaySpeed: 5000
};

const StateBranchesTemplate = ({ location, data }) => {
  const stateData = data.strapiBranchState;
  const StateName = stateData.StateName;
  const StateCode = stateData.StateCode;

  const pageLocation = { location };

  const SearchData = {
    StateName: StateName,
    StateCode: stateData.StateCode,
    disableLocalSearch: StateCode === "CA"
  };

  const metaTitle =
    stateData.SEO && stateData.SEO.MetaTitle && stateData.SEO.MetaTitle !== ""
      ? stateData.SEO.MetaTitle
      : "Best Bank in " + StateName;
  const metaDescription =
    stateData.SEO && stateData.SEO.MetaDescription && stateData.SEO.MetaDescription
      ? stateData.SEO.MetaDescription
      : "Welcome to WaFd Bank of " +
        StateName +
        " we provide checking, savings, and mortgage services in the communities we serve. Visit a branch near you today!";

  const heroData = {
    AZ: { imgDateStamp: "04132023", altText: "Downtown skyline in Tucson, Arizona." },
    CA: { imgDateStamp: "02082024", altText: "Downtown San Jose, California city skyline." },
    ID: { imgDateStamp: "04132023", altText: "Downtown skyline in Boise, Idaho with hot air balloons." },
    NM: { imgDateStamp: "04132023", altText: "Downtown skyline if Albuquerque, New Mexico." },
    NV: { imgDateStamp: "09112023", altText: "Las Vegas skyline and residential area." },
    OR: { imgDateStamp: "04132023", altText: "Downtown skyline in Portland, Oregon." },
    TX: { imgDateStamp: "04132023", altText: "Downtown skyline of El Paso, Texas." },
    UT: { imgDateStamp: "04132023", altText: "Downtown skyline in Salt Lake City, Utah." },
    WA: { imgDateStamp: "04132023", altText: "Downtown Seattle skyline with Space Needle." }
  };

  const heroChevronData = {
    id: "state-branches-hero",
    ...getHeroImgVariables(data),
    altText: heroData[StateCode].altText,
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Open a *Free Checking Account in " + StateName
          }
        },
        {
          id: 2,
          button: {
            id: "state-branches-hero-cta",
            text: "Get Free Checking",
            url: `/personal-banking/free-checking-account/${stateData.Slug}`,
            class: "btn-white",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          tertiaryText: {
            class: "text-white",
            text: "*Nonsufficient funds charge may apply."
          }
        }
      ]
    }
  };

  const SEOData = {
    title: metaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: metaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: metaDescription
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/locations/" + stateData.Slug
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content:
          "https://www.wafdbank.com/images/adsearch/states/og-locations-" +
          stateData.Slug +
          "-" +
          heroData[StateCode].imgDateStamp +
          ".jpg"
      }
    ]
  };

  const cityPageLinks = {
    WA: {
      city1Name: "Seattle",
      city1Url: "/locations/washington/seattle",
      city2Name: "Bellingham",
      city2Url: "/locations/washington/bellingham",
      city3Name: "Everett",
      city3Url: "/locations/washington/everett"
    },
    ID: {
      city1Name: "Boise",
      city1Url: "/locations/idaho/boise",
      city2Name: "Nampa",
      city2Url: "/locations/idaho/nampa",
      city3Name: "Pocatello",
      city3Url: "/locations/idaho/pocatello"
    },
    NV: {
      city1Name: "Las Vegas",
      city1Url: "/locations/nevada/las-vegas",
      city2Name: "Mesquite",
      city2Url: "/locations/nevada/mesquite",
      city3Name: "Elko",
      city3Url: "/locations/nevada/elko"
    },
    UT: {
      city1Name: "Salt Lake City",
      city1Url: "/locations/utah/salt-lake-city",
      city2Name: "Price",
      city2Url: "/locations/utah/price",
      city3Name: "Logan",
      city3Url: "/locations/utah/logan"
    },
    AZ: {
      city1Name: "Phoenix",
      city1Url: "/locations/arizona/phoenix",
      city2Name: "Tucson",
      city2Url: "/locations/arizona/tucson",
      city3Name: "Yuma",
      city3Url: "/locations/arizona/yuma"
    },
    NM: {
      city1Name: "Albuquerque",
      city1Url: "/locations/new-mexico/albuquerque",
      city2Name: "Santa Fe",
      city2Url: "/locations/new-mexico/santa-fe",
      city3Name: "Las Cruces",
      city3Url: "/locations/new-mexico/las-cruces"
    },
    TX: {
      city1Name: "Dallas",
      city1Url: "/locations/texas/dallas",
      city2Name: "Austin",
      city2Url: "/locations/texas/austin",
      city3Name: "Plano",
      city3Url: "/locations/texas/plano"
    },
    OR: {
      city1Name: "Eugene",
      city1Url: "/locations/oregon/eugene",
      city2Name: "Portland",
      city2Url: "/locations/oregon/portland",
      city3Name: "Medford",
      city3Url: "/locations/oregon/medford"
    },
    CA: {
      city1Name: "Santa Rosa",
      city1Url: "/locations/california/santa-rosa",
      city2Name: "El Segundo",
      city2Url: "/locations/california/el-segundo",
      city3Name: "Long Beach",
      city3Url: "/locations/california/long-beach"
    }
  };

  let quickLinksData = [
    {
      icon: "home-heart",
      lib: "fas",
      url: "/personal-banking/home-loans",
      id: "quick-links-home-loans-link",
      text: "Home Loans"
    },
    {
      icon: "credit-card",
      lib: "far",
      url: "/personal-banking/credit-cards",
      id: "quick-links-credit-cards-link",
      text: "Credit Cards"
    },
    {
      icon: "money-check-edit",
      lib: "fas",
      url: "/personal-banking/checking-account",
      id: "quick-links-checking-accounts-link",
      text: "Checking Accounts"
    },
    {
      icon: "piggy-bank",
      lib: "fas",
      url: "/personal-banking/savings-account",
      id: "quick-links-savings-accounts-link",
      text: "Savings Accounts"
    },
    {
      icon: "handshake-alt",
      lib: "fas",
      url: "/business-banking/small-business",
      id: "quick-links-business-banking-link",
      text: "Business"
    }
  ];

  // Remove Credit Cards Link from Quick Links Data for the California State Page
  if (StateCode === "CA") {
    quickLinksData = quickLinksData.filter((quickLink) => quickLink.url !== "/personal-banking/home-loans");
  }

  const CardsData = {
    AZ: {
      bestBanksPageLink: "/about-us/best-bank",
      csrImageVariable: data.csrArizona.childImageSharp.gatsbyImageData,
      csrAltText: "WaFd employees volunteering at the Pasta Drive.",
      helocStatePath: "/personal-banking/home-loans/heloc/arizona",
      constructionStatePath: "/personal-banking/home-loans/construction-loans/arizona",
      commercialStatePath: "/commercial-banking/arizona",
      helocCardImageAltText: "Arizona home with spring flowers.",
      constructionCardImageAltText: "New home construction below the Santa Catalina Mountains in Arizona.",
      commercialCardImageAltText: "Panoramic view of Phoenix, Arizona."
    },
    CA: {
      bestBanksPageLink: "/about-us/best-bank",
      csrImageVariable: data.csrCalifornia.childImageSharp.gatsbyImageData,
      csrAltText: "Volunteer smiling handing canned food to another person.",
      commercialStatePath: "/commercial-banking/arizona",
      commercialCardImageAltText: "Panoramic view of Phoenix, Arizona."
    },
    ID: {
      bestBanksPageLink: "/about-us/best-bank/idaho",
      csrImageVariable: data.csrIdaho.childImageSharp.gatsbyImageData,
      csrAltText: "WaFd employees volunteering at the Idaho Food Bank.",
      helocStatePath: "/personal-banking/home-loans/heloc/idaho",
      constructionStatePath: "/personal-banking/home-loans/construction-loans/idaho",
      commercialStatePath: "/commercial-banking/idaho",
      helocCardImageAltText: "Idaho home with spring flowers.",
      constructionCardImageAltText: "New home construction in Coeur d'Alene, Idaho",
      commercialCardImageAltText: "Sunset over Capitol of Idaho, City of Boise skyline."
    },
    NM: {
      bestBanksPageLink: "/about-us/best-bank/new-mexico",
      csrImageVariable: data.csrNewMexico.childImageSharp.gatsbyImageData,
      csrAltText: "WaFd employees volunteering for Financial Literacy at Wonder Word Daycare.",
      helocStatePath: "/personal-banking/home-loans/heloc/new-mexico",
      constructionStatePath: "/personal-banking/home-loans/construction-loans/new-mexico",
      commercialStatePath: "/commercial-banking/new-mexico",
      helocCardImageAltText: "New Mexico home with spring flowers.",
      constructionCardImageAltText: "New home construction in Santa Fe, New Mexico.",
      commercialCardImageAltText: "Albuquerque Plaza WaFd Bank Building."
    },
    NV: {
      bestBanksPageLink: "/about-us/best-bank/nevada",
      csrImageVariable: data.csrNevada.childImageSharp.gatsbyImageData,
      csrAltText: "WaFd employees volunteering, Teaching Financial Literacy.",
      helocStatePath: "/personal-banking/home-loans/heloc/nevada",
      constructionStatePath: "/personal-banking/home-loans/construction-loans/nevada",
      commercialStatePath: "/commercial-banking/nevada",
      helocCardImageAltText: "Nevada home in the spring.",
      constructionCardImageAltText: "New home construction in Reno, Nevada.",
      commercialCardImageAltText: "Panoramic view of Las Vegas Nevada."
    },
    OR: {
      bestBanksPageLink: "/about-us/best-bank/oregon",
      csrImageVariable: data.csrOregon.childImageSharp.gatsbyImageData,
      csrAltText: "WaFd employees volunteering at the ACCESS Food Pantry.",
      helocStatePath: "/personal-banking/home-loans/heloc/oregon",
      constructionStatePath: "/personal-banking/home-loans/construction-loans/oregon",
      commercialStatePath: "/commercial-banking/oregon",
      helocCardImageAltText: "Oregon home with spring flowers.",
      constructionCardImageAltText: "New home construction in Bend, Oregon.",
      commercialCardImageAltText: "Panoramic view of Portland, Oregon."
    },
    TX: {
      bestBanksPageLink: "/about-us/best-bank",
      csrImageVariable: data.csrTexas.childImageSharp.gatsbyImageData,
      csrAltText: "WaFd employees volunteering at the Central Texas Food Bank.",
      helocStatePath: "/personal-banking/home-loans/heloc/texas",
      constructionStatePath: "/personal-banking/home-loans/construction-loans/texas",
      commercialStatePath: "/commercial-banking/texas",
      helocCardImageAltText: "3d printed homes under construction in North Austin, Texas.",
      constructionCardImageAltText: "New home construction in Houston, Texas.",
      commercialCardImageAltText: "Dallas, Texas skyline at dusk."
    },
    UT: {
      bestBanksPageLink: "/about-us/best-bank",
      csrImageVariable: data.csrUtah.childImageSharp.gatsbyImageData,
      csrAltText: "WaFd employees volunteering at Project Homeless Connect.",
      helocStatePath: "/personal-banking/home-loans/heloc/utah",
      constructionStatePath: "/personal-banking/home-loans/construction-loans/utah",
      commercialStatePath: "/commercial-banking/utah",
      helocCardImageAltText: "Utah home in the spring.",
      constructionCardImageAltText: "New home construction in Spanish Fork, Utah.",
      commercialCardImageAltText: "Panoramic view of Salt Lake City, Utah."
    },
    WA: {
      bestBanksPageLink: "/about-us/best-bank/washington-state",
      csrImageVariable: data.csrWashington.childImageSharp.gatsbyImageData,
      csrAltText: "WaFd employees volunteering at Stuff the Bus Helping Hands.",
      helocStatePath: "/personal-banking/home-loans/heloc/washington",
      constructionStatePath: "/personal-banking/home-loans/construction-loans/washington",
      commercialStatePath: "/commercial-banking/washington",
      helocCardImageAltText: "Washington home with spring flowers.",
      constructionCardImageAltText: "New home construction in Spokane Valley, Washington.",
      commercialCardImageAltText: "Seattle, Washington downtown view."
    }
  };

  const CD_SPECIAL_APY_7_MONTH = data.allStrapiProducts.nodes[0].ProductData.find(
    (product) => product.name === "APY - Eligible Checking"
  ).value;

  const largeCardsData = {
    sectionClass: "bg-white pt-0",
    rowColsClass: "row-cols-1 row-cols-md-2",
    cards: [
      {
        id: "credit-card-link-card",
        url: "/personal-banking/credit-cards",
        image: {
          imgVariable: data.largeCard1.childImageSharp.gatsbyImageData,
          altText: "Young woman sitting outside with credit card in hand and tablet."
        },
        title: "Low Rates, Double the Perks With up to 2% Cash Back",
        text: "Maximize your savings on everyday purchases with a WaFd credit card.",
        showTextArrow: true
      },
      {
        id: "cd-link-card",
        url: "/personal-banking/savings-account/certificate-of-deposit",
        image: {
          imgVariable: data.largeCard2.childImageSharp.gatsbyImageData,
          altText: "Family playing while cooking in the kitchen."
        },
        title: "Lock in up to " + CD_SPECIAL_APY_7_MONTH + " Annual Percentage Yield CD Specials",
        text: "Earn interest on a limited time 7-month CD special.",
        showTextArrow: true
      }
    ]
  };

  const californiaLargeCardsData = {
    sectionClass: "bg-white pt-0",
    rowColsClass: "row-cols-1 row-cols-md-2",
    cards: [
      {
        id: "green-light-link-card",
        url: "/personal-banking/greenlight-kids-debit-card",
        image: {
          imgVariable: data.greenLightCard.childImageSharp.gatsbyImageData,
          altText: "Mother and daughter smiling while looking at the green light app and credit card."
        },
        title: "Raise Financially Savvy Kids with Greenlight",
        text: "Fun games, their own debit card, and parental controls courtesy of WaFd.",
        showTextArrow: true
      },
      {
        id: "business-services-link-card",
        url: "/business-banking/business-services",
        image: {
          imgVariable: data.businessLinkCard.childImageSharp.gatsbyImageData,
          altText: "Woman checking out at vegetable stand with a mobile phone."
        },
        title: "Streamline Your Business Processes with WaFd Business Services",
        text: "With services ranging from accounting automation to customer engagement, we're here to help so you can get back to doing what you love.",
        showTextArrow: true
      }
    ]
  };

  const featuredBranch = stateData.FeaturedBranches[0]?.branch_location;
  // Can't get the Address component from the featured branch directly (Maybe a bug in Strapi?!).
  // Since we can get all the branch locations and their info directly, filtering the featured branch to get the address
  const featuredBranchFromBranchLocations = stateData.branch_locations.find(
    (branch) => branch.PageURL === featuredBranch.PageURL
  );

  const featuredBranchAddress = featuredBranchFromBranchLocations.Address;
  const featuredBranchAddressUrl =
    "https://maps.google.com/maps?daddr=" +
    featuredBranchAddress.StreetAddress +
    "%2c" +
    featuredBranchAddress.City +
    "%2c" +
    StateCode +
    "%2c" +
    featuredBranchAddress.ZipCode;

  const makeAppointmentUrl = "/make-an-appointment?BranchName=" + featuredBranch.MSBookingsBranchName;

  return (
    <SecondaryLanding footerBorder={false}>
      <HeroChevron {...heroChevronData} />
      <BreadcrumbAuto {...pageLocation} />
      <SEO {...SEOData} />
      <div id="state-branches-wrapper">
        <section id="state-branches-overview-section" className="bg-white">
          <div className="container">
            <h1 className="mb-3">{StateName}</h1>
            <div className="row">
              <div className={`${StateCode === "ID" ? "col-md-8" : "col-12"}`}>
                {StateCode === "CA" ? (
                  <h3>
                    Folks in the Golden State trust WaFd Bank for all their personal and commercial banking needs.
                    Whether you're looking for a money market in{" "}
                    <Link
                      id={`${cityPageLinks[StateCode].city1Name.toLowerCase()}-locations-link`}
                      to={cityPageLinks[StateCode].city1Url}
                    >
                      {cityPageLinks[StateCode].city1Name}
                    </Link>{" "}
                    or to open a business account in{" "}
                    <Link
                      id={`${cityPageLinks[StateCode].city2Name.toLowerCase()}-locations-link`}
                      to={cityPageLinks[StateCode].city2Url}
                    >
                      {cityPageLinks[StateCode].city2Name}
                    </Link>
                    , or free checking in{" "}
                    <Link
                      id={`${cityPageLinks[StateCode].city3Name.toLowerCase()}-locations-link`}
                      to={cityPageLinks[StateCode].city3Url}
                    >
                      {cityPageLinks[StateCode].city3Name}
                    </Link>
                    , we're here to serve you. For more than 100 years WaFd Bank has served our communities in the West.
                  </h3>
                ) : (
                  <h3>
                    Folks in the great state of {StateName} trust WaFd Bank for all their personal and commercial
                    banking needs. Whether you're looking for a mortgage in{" "}
                    <Link
                      id={`${cityPageLinks[StateCode].city1Name.toLowerCase()}-locations-link`}
                      to={cityPageLinks[StateCode].city1Url}
                    >
                      {cityPageLinks[StateCode].city1Name}
                    </Link>{" "}
                    or to open a business checking account in{" "}
                    <Link
                      id={`${cityPageLinks[StateCode].city2Name.toLowerCase()}-locations-link`}
                      to={cityPageLinks[StateCode].city2Url}
                    >
                      {cityPageLinks[StateCode].city2Name}
                    </Link>
                    , or free checking in{" "}
                    <Link
                      id={`${cityPageLinks[StateCode].city3Name.toLowerCase()}-locations-link`}
                      to={cityPageLinks[StateCode].city3Url}
                    >
                      {cityPageLinks[StateCode].city3Name}
                    </Link>
                    , we are here to serve you. For more than 100 years WaFd bank has served the communities of the
                    West.
                  </h3>
                )}
              </div>
              {StateCode === "ID" && (
                <div className="col-md-4">
                  <img
                    src="/images/wafd-bank-idaho-best-regional-bank-2024.png"
                    alt="Idaho Business Review Reader Rankings 2024 Best Regional bank Winner badge."
                    className="w-100"
                  />
                </div>
              )}
            </div>
          </div>
        </section>
        {/* Find a location section */}
        <section className="bg-light">
          <div className="container pt-0">
            <div className="row">
              <div className="col">
                <h3 className="text-success font-weight-semi-bold">
                  Find a Location Near You
                  <Icon name="map-marker-alt" lib="far" class="text-success ml-2" />
                </h3>
                <p>We're nearby, and we'll sit down with you and answer any questions you may have.</p>

                <div className="d-md-flex flex-column flex-md-row d-none">
                  <Link
                    to="#branch-search-section"
                    id="find-a-branch-near-you-cta"
                    class="btn btn-primary w-100 w-sm-auto mr-3"
                  >
                    Find a Local Branch
                  </Link>
                  <Link to="/open-an-account" id="open-account-cta" className="btn btn-link w-100 w-sm-auto">
                    Open an Account
                  </Link>
                </div>
                <div className="d-block d-md-none">
                  <Link
                    to="#branch-search-section"
                    id="find-a-branch-near-you-cta"
                    class="btn btn-primary w-100 w-md-auto mb-3"
                  >
                    Find a Local Branch
                  </Link>
                  <Link to="/open-an-account" id="open-account-cta" className="btn btn-link w-100 w-md-auto mb-3">
                    Open an Account
                  </Link>
                </div>
              </div>
              <div className="col-md-4 border-md-left">
                {quickLinksData.map((data, index) => (
                  <h5 key={index}>
                    <Link to={data.url} id={data.id} className="text-decoration-none">
                      <Icon name={data.icon} lib={data.lib} class="mr-2" />
                      {data.text}
                    </Link>
                  </h5>
                ))}
              </div>
            </div>
          </div>
        </section>
        {/* Link Cards */}
        <section className="container">
          <div className={`row row-cols-1 row-cols-md-2 ${StateCode === "CA" ? "" : "row-cols-lg-3"}`}>
            <div id="best-banks-link-card" className="col mb-4">
              <div
                className={`card h-100 position-relative overflow-hidden border-radius-12 border-0 ${styles.stretchedLinkCard}`}
              >
                {(StateCode === "WA" || StateCode === "ID" || StateCode === "CA") && (
                  <>
                    <StaticImage
                      src="../../images/thumbnail-best-bank-and-best-in-state-2023.jpg"
                      alt="WaFd Bank voted Best Bank by Newsweek."
                      className="border-radius-bottom-0"
                      placeholder="blurred"
                      quality="100"
                    />
                    <div className="card-body d-flex flex-column align-items-start">
                      <h3 className="card-title text-success">
                        {StateCode === "CA" ? (
                          <>WaFd is Proud to be Awarded America's Best Bank</>
                        ) : (
                          <>WaFd is Proud to be Awarded America's Best Big Bank and Best-in-State in {StateName}!</>
                        )}
                      </h3>
                      <p className={`card-text ${styles.cardDescription}`}>
                        Working together isn't new for us. Here's how we earned this honor.
                        <Icon name="arrow-right" class="ml-1 text-primary" />
                      </p>
                      <Link
                        id="state-best-banks-page-link"
                        to={CardsData[StateCode].bestBanksPageLink}
                        className="stretched-link"
                      />
                    </div>
                  </>
                )}
                {(StateCode === "OR" || StateCode === "NV") && (
                  <>
                    <StaticImage
                      src="../../images/thumbnail-best-in-state-2023.jpg"
                      alt="WaFd Bank voted Best Bank by Newsweek."
                      className="border-radius-bottom-0"
                      placeholder="blurred"
                      quality="100"
                    />
                    <div className="card-body d-flex flex-column align-items-start">
                      <h3 className="card-title text-success">
                        WaFd Bank is Proud to be Awarded Best-in-State Banks in {StateName}!
                      </h3>
                      <p className={`card-text ${styles.cardDescription}`}>
                        Working together isn't new for us. Here's how we earned this honor.
                        <Icon name="arrow-right" class="ml-1 text-primary" />
                      </p>
                      <Link
                        id="state-best-banks-page-link"
                        to={CardsData[StateCode].bestBanksPageLink}
                        className="stretched-link"
                      />
                    </div>
                  </>
                )}
                {(StateCode === "TX" || StateCode === "NM" || StateCode === "AZ" || StateCode === "UT") && (
                  <>
                    <StaticImage
                      src="../../images/thumbnail-best-bank-2023.jpg"
                      alt="WaFd Bank voted Best Bank by Newsweek."
                      className="border-radius-bottom-0"
                      placeholder="blurred"
                      quality="100"
                    />
                    <div className="card-body d-flex flex-column align-items-start">
                      <h3 className="card-title text-success">
                        {StateCode === "NM"
                          ? `WaFd Bank is Proud to be Awarded Best Big Bank in ${StateName}!`
                          : `WaFd is Proud to be Awarded America's Best Big Bank Three Years in a Row`}
                      </h3>
                      <p className={`card-text ${styles.cardDescription}`}>
                        Working together isn't new for us. Here's how we earned this honor.
                        <Icon name="arrow-right" class="ml-1 text-primary" />
                      </p>
                      <Link
                        id="state-best-banks-page-link"
                        to={CardsData[StateCode].bestBanksPageLink}
                        className="stretched-link"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div id="community-relations-card" className="col mb-4">
              <div
                className={`card h-100 position-relative overflow-hidden border-radius-12 border-0 ${styles.stretchedLinkCard}`}
              >
                <GatsbyImage
                  image={CardsData[StateCode].csrImageVariable}
                  alt={CardsData[StateCode].csrAltText}
                  className="border-radius-bottom-0"
                />
                <div className="card-body d-flex flex-column align-items-start">
                  <h3 className="card-title text-success">{StateName} WaFd employees are invested in our community</h3>
                  <p className={`card-text ${styles.cardDescription}`}>
                    See how WaFd is giving back to {StateName} communities and in all of our communities across all
                    states we serve.
                    <Icon name="arrow-right" class="ml-1 text-primary" />
                  </p>
                  <Link
                    id="csr-volunteerism-card-link"
                    aria-hidden="true"
                    tabIndex="-1"
                    to="/about-us/investor-relations/csr#volunteerism"
                    className="stretched-link"
                  />
                </div>
              </div>
            </div>
            {StateCode !== "CA" && (
              <div className="col mb-4" id="branch-reviews-slider">
                <h3 className="text-success font-weight-semi-bold">What our customers in {StateName} are saying</h3>
                <Slider {...settings}>
                  {stateData.FeaturedReviews.map((review, index) => (
                    <div key={index}>
                      <p id={`review-${index + 1}-text`}>&ldquo;{review.ReviewText}&rdquo;</p>
                      <p className="font-weight-bold">
                        <span id={`review-${index + 1}-author-name`}>{review.AuthorsName}</span>
                        <br />
                        <Link
                          className="text-decoration-none"
                          to={review.branch_location.PageURL}
                          id={`review-${index + 1}-branch-link`}
                        >
                          {review.ReviewTitle} Branch
                          <Icon name="arrow-right" class="ml-2" />
                        </Link>
                      </p>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </section>
        {/* NO MORTGAGE CARDS FOR CA */}
        {StateCode !== "CA" && (
          <section className="container pt-0">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
              {data.helocCardImage && (
                <div id="state-heloc-card" className="col mb-4">
                  <div
                    className={`card h-100 position-relative overflow-hidden border-radius-12 border-0 ${styles.stretchedLinkCard}`}
                  >
                    <GatsbyImage
                      image={data.helocCardImage.childImageSharp.gatsbyImageData}
                      alt={CardsData[StateCode].helocCardImageAltText}
                      className="border-radius-bottom-0"
                    />
                    <div className="card-body d-flex flex-column align-items-start">
                      <h3 className="card-title text-success">
                        Get a Home Equity Line of Credit (HELOC) in {StateName}
                      </h3>
                      <p className={`card-text ${styles.cardDescription}`}>
                        A no annual fee HELOC from WaFd Bank is often more affordable than using credit cards or other
                        loans.
                        <Icon name="arrow-right" class="ml-1 text-primary" />
                      </p>
                      <Link
                        id="state-heloc-page-link"
                        aria-hidden="true"
                        to={CardsData[StateCode].helocStatePath}
                        className="stretched-link"
                      />
                    </div>
                  </div>
                </div>
              )}
              {data.constructionCardImage && (
                <div id="state-construction-card" className="col mb-4">
                  <div
                    className={`card h-100 position-relative overflow-hidden border-radius-12 border-0 ${styles.stretchedLinkCard}`}
                  >
                    <GatsbyImage
                      image={data.constructionCardImage.childImageSharp.gatsbyImageData}
                      alt={CardsData[StateCode].constructionCardImageAltText}
                      className="border-radius-bottom-0"
                    />
                    <div className="card-body d-flex flex-column align-items-start">
                      <h3 className="card-title text-success">Build your Dream Home in {StateName}!</h3>
                      <p className={`card-text ${styles.cardDescription}`}>
                        Our local professionals specialize in home construction loans, application through completion of
                        your new home.
                        <Icon name="arrow-right" class="ml-1 text-primary" />
                      </p>
                      <Link
                        id="state-construction-page-link"
                        aria-hidden="true"
                        tabIndex="-1"
                        to={CardsData[StateCode].constructionStatePath}
                        className="stretched-link"
                      />
                    </div>
                  </div>
                </div>
              )}
              {data.commercialCardImage && (
                <div id="state-commercial-card" className="col mb-4">
                  <div
                    className={`card h-100 position-relative overflow-hidden border-radius-12 border-0 ${styles.stretchedLinkCard}`}
                  >
                    <GatsbyImage
                      image={data.commercialCardImage.childImageSharp.gatsbyImageData}
                      alt={CardsData[StateCode].commercialCardImageAltText}
                      className="border-radius-bottom-0"
                    />
                    <div className="card-body d-flex flex-column align-items-start">
                      <h3 className="card-title text-success">Meet our {StateName} Commercial Banking Team</h3>
                      <p className={`card-text ${styles.cardDescription}`}>
                        If you have commercial needs, our {StateName} Commercial Banking team can help.
                        <Icon name="arrow-right" class="ml-1 text-primary" />
                      </p>
                      <Link
                        id="state-commercial-page-link"
                        aria-hidden="true"
                        tabIndex="-1"
                        to={CardsData[StateCode].commercialStatePath}
                        className="stretched-link"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}
        {StateCode === "CA" && <StretchedLinkCards {...californiaLargeCardsData} />}
        <StretchedLinkCards {...largeCardsData} />
        <section className="bg-blue-10">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                {featuredBranch.BranchImageUrl ? (
                  <img
                    src={featuredBranch.BranchImageUrl}
                    alt={featuredBranch.Title}
                    className="border-radius-12 mw-100"
                  />
                ) : null}
                <div className="d-flex my-3">
                  <Icon name="map-marker-alt" lib="far" class="text-primary mr-2 mt-1" />
                  <a
                    href={featuredBranchAddressUrl}
                    className="text-decoration-none font-weight-bold"
                    id="featured-branch-address-cta"
                  >
                    {featuredBranchAddress.StreetAddress}, {featuredBranchAddress.City},{" "}
                    {featuredBranchAddress.State === "NewMexico" ? "New Mexico" : featuredBranchAddress.State}
                    &nbsp;&nbsp;
                    {featuredBranchAddress.ZipCode}
                  </a>
                </div>
              </div>
              <div className="col-md-7">
                <h2 className="font-weight-semi-bold text-success">{stateData.FeaturedBranches[0].Title}</h2>
                <h5 className="mb-0">{stateData.FeaturedBranches[0].Subtitle}</h5>
                <h5 className="font-weight-normal">
                  Walk in our branch and you'll see this is your neighborhood bank, with bankers that care about knowing
                  your name and helping you find financial success right here in the community.
                </h5>
                <div className="row">
                  <Button
                    containerClass="col-lg-auto mb-3 mb-lg-0"
                    url={featuredBranch.PageURL}
                    class="btn-primary w-100 no-min-width"
                    text={featuredBranch.Title}
                    id="featured-branch-link"
                  />
                  {featuredBranch.MSBookingsBranchName && (
                    <Button
                      containerClass="col-lg-auto mb-3 mb-lg-0"
                      url={makeAppointmentUrl}
                      class="btn-link w-100 no-min-width"
                      id="featured-branch-make-an-appointment-link"
                      text="Make an Appointment"
                      showIcon={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <BranchSearch {...SearchData} />
        <BestBanksDefault />
      </div>
    </SecondaryLanding>
  );
};

StateBranchesTemplate.propTypes = {
  branch: PropTypes.object
};

export default StateBranchesTemplate;
